import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import SimpleTable from '../../../../components/SimpleTable';
import styles from "../../../../styles/index";
import {
  fetchAllTaxProfiles,
  fetchTaxProfileAssociationList,
  deleteTaxProfile,
} from "../../../../redux/actions";
import {
  AvenirBlackH4,
  TBold,
  T,
  DotText,
} from "../../../../utils/text";
import CirclePluse from "../../../../components/SVG/PluseIcon";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import MyContext from "../../../../Provider/MyContext";
import SnackBar from "../../../../components/SnackBar";
import _ from "lodash";
import CreateTaxProfile from "./createTaxProfile";
import EditTaxProfile from "./editTaxProfile";
const TaxProfilesList = ({ open, onClose, callback }) => {
  const style = styles();
  const Context = useContext(MyContext);
  const taxProfiles = useSelector(
    (state) => state.taxConfigurationReducer.taxProfiles
  );
  const taxProfileAssociations = useSelector(
    (state) => state.taxConfigurationReducer.taxProfileAssociations
  );
  const [selectedId, setSelectedId] = React.useState([]);
  const [btnDisable, setBtnDisable] = React.useState(true);
  const [openCreate, toggleCreateDrawer] = useState(false);
  const [openEdit, toggleEditDrawer] = useState(false);
  const [row, setRow] = useState({});
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [heading, setHeading] = useState(Context.langData.delete_tax_profile);
  const [taxes, setTaxes] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const restId = useSelector((state) => state.loginReducer.selectedRestaurant);

  const bodyMessage =
    selectedId.length > 1
      ? "Are you sure you want to delete tax profile" +
      selectedId.length +
      " tax profiles?"
      : Context.langData.delete_tax_profile_body;

  useEffect(() => {
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
    dispatch(fetchTaxProfileAssociationList());
  }, []);

  useEffect(() => {
    setTaxes(processTaxProfiles(taxProfiles, taxProfileAssociations));
  }, [taxProfiles, taxProfileAssociations]);


  function processTaxProfiles(taxProfiles, taxProfileAssociations) {
    return taxProfiles.map((d) => {
      let hasAssigned = false;
      let taxReductionText = Context.langData.tax_profile_not_applied;
      if (d.hasSpecialRule)
        taxReductionText = Context.langData.tax_profile_applied;
      let categoryText = "0 " + Context.langData.categories + ", 0 " + Context.langData.items;
      if (taxProfileAssociations) {
        const taxProfileAssociation = taxProfileAssociations.find(e=>e.id === d.id);
        if (taxProfileAssociation) {
          var itemCount = taxProfileAssociation.items.length;
          taxProfileAssociation.categories.forEach(element => {
            itemCount = itemCount + element.items.length;
          });
          categoryText = taxProfileAssociation.categories.length + " " + Context.langData.categories + ", " + itemCount + " " + Context.langData.items;
          hasAssigned = (taxProfileAssociation.categories.length ==0 && itemCount==0) ? false:true;
        }
      }
      return {
        ...d,
        taxReductionText: taxReductionText,
        categoryText: categoryText,
        hasAssigned: hasAssigned
      };
    });
  }

  const [columns, setColumns] = useState([
    {
      field: "name",
      display: Context.langData.tax_profile,
      size: "20%",
      sort: false,
    },
    {
      field: "desc",
      display: Context.langData.comment,
      size: "20%",
      sort: false,
    },
    {
      field: "taxes",
      display: "Tax",
      size: "20%",
      sort: false,
      // showSort: false
    },
    {
      field: "taxReductionText",
      display: Context.langData.tax_reduction_column,
      size: "20%",
      sort: false,
    },
    {
      field: "categoryText",
      display: Context.langData.categories,
      size: "20%",
      sort: false,
    },
  ]);
  function onClickAction(rowValue, event) {
    setRow(rowValue);
    toggleEditDrawer(true);
  }
  const btnProps = {};
  if (btnDisable) {
    btnProps["disabled"] = true;
  }
  function commonCallback() {
    toggleCreateDrawer(false);
    toggleEditDrawer(false);
    dispatch(fetchAllTaxProfiles({ id: restId.rest_id }));
  }
  function createCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_create_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_create_failed,
        severity: "error",
      });
    }
    commonCallback();
  }
  function editCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_edit_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_edit_failed,
        severity: "error",
      });
    }
    commonCallback();
  }
  function deleteCallback(isSuccess) {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.tax_profile_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.tax_profile_delete_failed,
        severity: "error",
      });
    }
    commonCallback();
  }

  const handleClose = (val) => {
    if (val) {
      for (const i of selectedId) {
        dispatch(
          deleteTaxProfile(restId.rest_id, JSON.stringify(i), deleteCallback)
        );
      }
    }
    setShowDialog(false);
  };

  const sortingOnClick = (value, sortValue, index) => {
    var columnData = [...columns];
    sortValue = !sortValue
    columnData[index] = { ...columnData[index], sort: !sortValue };
    setColumns(columnData);
    var sortComments = _.orderBy(
      taxes,
      [
        (d) => {
          return d[value].toLowerCase();
        },
      ],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortComments, taxProfileAssociations));
    var sortTaxes = _.orderBy(
      taxes,
      [
        (d) => {
          return d[value].toLowerCase();
        },
      ],
      [sortValue ? "desc" : "asc"]
    );
    setTaxes(processTaxProfiles(sortTaxes, taxProfileAssociations));
  };
  console.log(taxes);

  return (
    <div data-qaid="taxeslist_div">
      <>
        <>
          <Grid
            data-qaid="taxesList-blurb"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 15,
              backgroundColor: "#F3FAFD",
              width: "95%",
              height: "auto",
              borderRadius: 5,
              marginBottom: 26,
              marginLeft: 20,
            }}>
            <Grid data-qaid="taxesList-blurb-InfoIcon">
              <InfoOutlined width={24} height={24} color={"#004C60"} />
            </Grid>
            <Grid style={{ paddingLeft: 10, width: "100%" }}>
              {showMore ? (
                <Grid
                  data-qaid="taxesList-blurb-message"
                  style={{
                    color: "#051D33",
                    fontSize: 14,
                    fontFamily: "AvenirLT",
                    lineHeight: 1.5,
                    paddingTop: 4,
                  }}>
                  {Context.langData.tax_important_reminder}
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={{ cursor: "pointer", color: "#004C60" }}>
                    {showMore ? " Show Less" : ""}
                  </span>
                </Grid>
              ) : (
                <Grid
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    paddingTop: 4,
                  }}>
                  <Grid
                    data-qaid="taxesList-blurb-message"
                    style={{ width: "83%" }}>
                    <DotText label={Context.langData.tax_important_reminder} />
                  </Grid>
                  <span
                    onClick={() => setShowMore(!showMore)}
                    style={{
                      cursor: "pointer",
                      color: "#004C60",
                      marginLeft: 3,
                    }}>
                    {showMore ? "" : "Read More"}
                  </span>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            data-qaid="taxeslist_grid"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: 20,
              marginBottom: 10,
              marginTop: 32,
            }}>
            
            <Button
              className={style.buttonClass}
              data-qaid="taxeslist_creategrid"
              style={{
                backgroundColor: "#FFB600",
                color: "white",
                marginRight: 10,
              }}
              variant="contained"
              id="cancel"
              onClick={() => toggleCreateDrawer(true)}>
              <CirclePluse data-qaid="taxeslist_createpluse" />
              <AvenirBlackH4
                data-qaid="taxeslist_createlabel"
                label={Context.langData.create_taxes_profile}
                style={{ paddingLeft: 5 }}
              />
            </Button>
          </Grid>
          {taxes.length > 0 ? (
            <SimpleTable
            data-qaid="taxProfiles_simpleTable"
            columns={columns}
            rows={taxes}
            isEditArrow={true}
            isOnClick={true}
            sortingOnClick={sortingOnClick}
            onRowClick={onClickAction}
          />
          ) : (
            <Grid
              data-qaid="customers_labelView"
              item
              lg={12}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                marginTop: "10%",
                flexDirection: "column",
              }}>
              <Grid
                item
                style={{
                  maxWidth: 410,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}>
                <TBold
                  label={Context.langData.no_profile_created_yet}
                  style={{ textAlign: "center", fontSize: 32 }}
                />
                <T
                  label={Context.langData.please_create_tax_profile}
                  style={{ marginTop: 20, fontSize: 18, color: "#051D33" }}
                />
              </Grid>
            </Grid>
          )}
        </>
      </>
      {openCreate && (
        <CreateTaxProfile
          data-qaid="taxeslist_createtaxes"
          open={openCreate}
          taxes={taxes}
          onClose={() => toggleCreateDrawer(false)}
          callback={createCallback}
        />
      )}
      {openEdit && (
        <EditTaxProfile
          data-qaid="taxeslist_edittaxes"
          open={openEdit}
          row={row}
          taxes={taxes}
          onClose={() => toggleEditDrawer(false)}
          editcallback={editCallback}
          deleteCallback={deleteCallback}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}

    </div>
  );
};

export default TaxProfilesList;
