import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    makeStyles,
    Snackbar, Button, Grid, Card, CardContent, CardActions, IconButton, Typography, InputLabel, Select, MenuItem, OutlinedInput, Chip
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '../../../../styles/index';
import RightDrawer from '../../../../components/RightDrawer'
import InputText from '../../../../components/InputText';
import {
    editPrinter,
    deletePrinters,
} from '../../../../redux/actions';
import SingleSelect from '../../../../components/SingleSelect';
import MyContext from '../../../../Provider/MyContext';
import { H4, AvenirBlackH4, H8, H5, AvenirRedH4} from '../../../../utils/text';
import DeleteIcon from '../../../../components/SVG/Delete';
import _ from 'lodash';
import SettingIcon from '../../../../components/SVG/Settings';
import CircleClose from '../../../../components/SVG/CloseCircle';
import MaskedInput from 'react-text-mask';
import ExclamationIcon from '../../../../components/SVG/ExclamationIcon';
import InfoOutlinedIcon from '../../../../components/SVG/InfoOutlined';
import MultiSelectChip from "../../../../components/MultiSelectChip";
import AlertDialog from '../../../../components/DialogDelete';

const EditPrinter = ({ open, row, callback, onClose, printers, deleteCallback }) => {
    const style = styles();
    const Context = useContext(MyContext);
    const Permissions = useSelector(state => state.profileReducers.permissions);
    const unassignedKitchens = useSelector(state => state.metaReducer.printerUnassigned);
    const [kitchens, setKitchens] = useState([]);
    const [kitchensArray, setKitchensArray] = useState([]);
    const [kitchenItemsArray, setKitchenItemsArray] = useState([]);
    const [name, setName] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [pModel, setPmodel] = useState(0);
    const printerModel = [
        { name: "Star TSP100" },
        { name: "Star SP700" },
    ]
    const [printerId, setPrinterId] = useState("");
    const [error, setError] = useState(null);
    const [ipAddresserror, setIpAddresserror] = useState(null);
    const [checkError, setCheckError] = useState(false)
    const [checkIt, setCheckIt] = useState([]);
    const checkList = useMemo(() => {
        let list = Permissions?.applications.filter(e => e.application_id != 1)
        return list
    }, [Permissions])
    const [showDialog, setShowDialog] = useState(false);
    const [alertData, setAlertData] = useState({
        open: false,
        severity: 'success',
        msg: '',
      });
    
    const isDeleteError = false;
    const deleteHeading = Context.langData.delete_printer;
    const [deleteBodyMessage, setDeleteBodyMessage] = useState(Context.langData.printer_body_delete);

    const handleCheck = (item) => {
        let ch = [...checkIt]
        let index = ch.indexOf(item.application_id)
        if (index !== -1) {
            ch = [...ch.slice(0, index), ...ch.slice(index + 1)]
        } else {
            ch = [...ch, item.application_id]
        }
        setCheckIt(ch)
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (row) {
            setPrinterId(row.id);
            setName(row.name);
            setIpAddress(row.ip_address);
            setPmodel(row.printer_model);
            if (row.assigned_kitchens) {
                console.log("kitchens ddl")
                console.log(row.assigned_kitchens)
                console.log(unassignedKitchens)
                setDeleteBodyMessage(Context.langData.printer_body_delete_warning);
                setKitchens([...row.assigned_kitchens, ...unassignedKitchens])
                setKitchensArray(row.assigned_kitchens.map(d => d["id"]))
            } else {
                setKitchens([...unassignedKitchens])
            }
        }
    }, [row])
    useEffect(() => { }, [kitchensArray]);
    const save = () => {
        var errorFlag = 0;
        var kitchenIds = [];
        if (name) {
        } else {
            errorFlag = errorFlag + 1;
            setError(Context.langData.no_printer_error);
        }
        if (ipAddress) {
            const regex = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/
            const m = regex.test(ipAddress)
            console.log(m);
            if (!m) {
                errorFlag = errorFlag + 1;
                setIpAddresserror(Context.langData.ip_address_error);
            } else {
                setIpAddresserror('');
            }
        } else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(Context.langData.enter_ipaddress_error);
        }
        console.log("save printer:")
        console.log(printerId)
        console.log(printers)
        var isArray = printers.filter((e) => e.id != printerId)
        console.log(isArray)
        console.log(name)
        const elementsIndex = isArray.findIndex(indexElement => indexElement.name == name);
        console.log(elementsIndex)
        if (elementsIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setError(name + " already exist");
        }
        const elementsipIndex = isArray.findIndex(indexElement => indexElement.ip_address == ipAddress);
        if (elementsipIndex == -1) {
        }
        else {
            errorFlag = errorFlag + 1;
            setIpAddresserror(ipAddress + " already exist");
        }
        if (errorFlag == 0) {
            for (const i of kitchensArray) {
                var vobj = {
                    kitchen_id: i,
                };
                kitchenIds.push(vobj);
            }
            if (kitchenIds.length > 0) {
                dispatch(editPrinter({ printer_id: printerId, ip_address: ipAddress.trim(), name: name.trim(), printer_model: pModel, kitchens: kitchenIds, }, callback));
            }
            else {
                dispatch(editPrinter({ printer_id: printerId, ip_address: ipAddress.trim(), name: name.trim(), printer_model: pModel, kitchens: [], }, callback));
            }
        }
    }
    const selectedKitchen = (value) => {
        var itemListArray = [...kitchensArray];
        var item = itemListArray.filter((e) => e == value);
        if (item.length > 0) {
        } else {
            itemListArray.push(value);
        }
        setKitchensArray(itemListArray);
    };
    const handleSelect = (value) => {
        selectedKitchen(value);
    };
    const handleMultiSelect = (e) => {
        console.log("edit multi select")
        console.log(e)
        setKitchensArray(e);
        setKitchenItemsArray(kitchens.filter((kitchen) => e.includes(kitchen.id)));
    };
    const onChange = (val) => {
        setName(val)
        if (printers.indexOf(val.toLowerCase()) == -1) {
            setError("")
        }
        else {
            setError(val + " already exist");
        }
    }
    // const onIpChange = (val) => {
    //     setIpAddress(val)
    //     if (printers.indexOf(val.toLowerCase()) == -1) {
    //         setError("")
    //     }
    //     else {
    //         setError(val + " already exist");
    //     }
    // }

    const props = {
        guide: false,
        placeholderChar: '\u2000',
        mask: value => Array(value.length).fill(/[\d.]/),
        pipe: value => {
            if (value === '.' || value.endsWith('..')) return false;

            const parts = value.split('.');
            if (
                parts.length > 4 ||
                parts.some(part => part === '00' || part < 0 || part > 255)
            ) {
                return false;
            }
            return value;
        },
    };

    const handleClose = (val) => {
        if (val) {
          dispatch(deletePrinters([row.id], deleteCallback));
        }
        setShowDialog(false);
    };
    
    const handleDelete = () => {
        setShowDialog(true);
      };

    return (
        <RightDrawer data-qaid="createcomment_centermodal" open={open} onClose={onClose} padding={1}>
            <>
                <Grid style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                    <Grid style={{ display: 'flex', height: '85%', flexDirection: 'column', overflowX: 'auto', marginBottom: 20 }}>
                        <Grid data-qaid="createcategory_close_grid" style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                            <CircleClose data-qaid="createcategory_closeicon" size={20} onClick={onClose} style={{ marginRight: 40, marginTop: 20 }} />
                        </Grid>
                        <Grid data-qaid="createcategory_settings_grid" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 56, marginBottom: 10 }}>
                            <SettingIcon data-qaid="createcategory_setting_icon" style={{ marginRight: 10 }} width={22.5} height={24} color='#707272' />
                            <H4 data-qaid="createcategory_settings" label={Context.langData.settings} style={{ color: '#707272' }} />
                        </Grid>
                        <H8 data-qaid="createcategory_title" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56 }} label={Context.langData.edit_printer} />
                        <Grid container direction="column" style={{ marginLeft: 56, marginBottom: 20, marginRight: 56, width: 500 }} >
                            <Grid data-qaid="editPrinter_name_grid" style={{ display: 'flex', flexDirection: 'column' }}>
                                <AvenirBlackH4 data-qaid="editPrinter_namelabel" label={Context.langData.printer_name} style={{ marginBottom: 12 }} />
                                <InputText
                                    data-qaid="editPrinter_nameinput"
                                    value={name}
                                    error={error}
                                    maxLength={64}
                                    onChange={(val) => onChange(val.target.value)}
                                    id="name"
                                    inputProps={{ maxLength: 64 }}
                                />
                                <Grid item style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginTop: 10, display: 'flex', paddingTop: 5 }}>
                                    <Grid item xs={9} style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex' }}>
                                        <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                        <H5
                                            label={"Name the printer after the kitchen station in which the printer is situated"}
                                            style={{ paddingLeft: 5 }} />
                                    </Grid>
                                    <Grid item xs={3} style={{ justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                        <H5 style={{ textAlign: 'right' }} label={(name?.length == undefined ? '0' : name?.length) + Context.langData._64_characters_count} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '55%' }}>
                                <AvenirBlackH4 data-qaid="" label={Context.langData.printer_model} style={{ marginBottom: 5 }} />
                                <SingleSelect
                                    list={printerModel}
                                    valueKey="name"
                                    displayKey="name"
                                    value={pModel}
                                    handleChange={(e) => setPmodel(e.target.value)}
                                    border={false}
                                    height={54}
                                    FormWidth={'100%'}
                                    borderStyle={'1px solid #707272'}
                                />
                            </Grid>
                            <Grid data-qaid="editPrinter_ipgrid" style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}>
                                <AvenirBlackH4 data-qaid="editPrinter_iplabel" label={Context.langData.ip_address} style={{ marginBottom: 12 }} />
                                {/* <InputText
                                    data-qaid="editPrinter_ipinput"
                                    value={ipAddress}
                                    error={ipAddresserror}
                                    onChange={(val) => onIpChange(val.target.value)}
                                    id="ip_address" /> */}
                                <MaskedInput
                                    {...props}
                                    style={{ height: 56, color: '#051D33', borderRadius: 4, paddingLeft: 10, border: ipAddresserror ? '1px solid #AB1212' : '1px solid #707272', fontSize: 16, fontFamily: 'AvenirLT' }}
                                    value={ipAddress}
                                    onChange={(val) => setIpAddress(val.target.value)}
                                />
                                {ipAddresserror &&
                                    <Grid style={{ flexDirection: 'row', alignItems: 'center', display: 'flex', marginTop: 5 }}>
                                        <ExclamationIcon color="#AB1212" style={{ marginRight: 5 }} />
                                        < H5 label={ipAddresserror} style={{ color: '#AB1212 ' }} />
                                    </Grid>
                                }
                                <Grid style={{ flexDirection: 'row', alignItems: 'flex-start', display: 'flex', paddingTop: 10 }}>
                                    <InfoOutlinedIcon style={{ height: 16, width: 16 }} />
                                    <H5
                                        label={"Enter the IP address obtained from the receipt that was printed during initial set up of printer"}
                                        style={{ paddingLeft: 5 }} />
                                </Grid>
                            </Grid>
                            <Grid
                                data-qaid="assignPrinter_kitchenlistGrid"
                                style={{ width: "100%", marginTop: 10 }}>
                                <AvenirBlackH4
                                    data-qaid="assignPrinter_kitchenLabel"
                                    label={Context.langData.assign_Printer}
                                    style={{ marginTop: 10, paddingBottom: 10 }}
                                />
                                <MultiSelectChip
                                    list={kitchens}
                                    value="id"
                                    name="name"
                                    placeholder={Context.langData.select_kitchen}
                                    selectedValue={kitchensArray}
                                    handleChange={handleMultiSelect}
                                    border={false}
                                    height={56}
                                    width={498}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ display: 'flex', height: '15%', flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Grid data-qaid="editPrinter_action_grid" container style={{ backgroundColor: '#FAFAFA' }}>
                        <Grid data-qaid="editPrinter_cancel_btn" item xs={12} lg={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 32, marginBottom: 32 }}>
                            <Button
                                className={style.buttonClass}
                                data-qaid="shifts_delete_button"
                                style={{ marginRight: 10 }}
                                variant="text"
                                id="cancel"
                                onClick={handleDelete}
                            >
                                <DeleteIcon
                                data-qaid="shifts_deleteicon"
                                width={16}
                                height={16}         
                                color = {'#8b0000'}                 
                                style={{ marginRight: 5 }}
                                />
                                <AvenirRedH4
                                data-qaid="shifts_delete_label"
                                label={Context.langData.delete_printer}    
                                />
                            </Button>
                            <Button className={style.buttonClass} data-qaid="editPrinter_cancel_button" onClick={onClose} variant="contained" style={{ backgroundColor: '#FFF', marginRight: 16, border: '2px solid #051D33', height: 40, width: 103 }} >
                                <AvenirBlackH4 data-qaid="editPrinter_cancel_text" label={Context.langData.cancel} />
                            </Button>
                            <Button
                                className={style.buttonClass}
                                data-qaid="editPrinter_save_btn"
                                variant="contained"
                                style={{ backgroundColor: '#FFB600', marginRight: 32, height: 40, width: 90 }}
                                onClick={save}>
                                <AvenirBlackH4 data-qaid="editPrinter_save_text" label={Context.langData.save} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <AlertDialog
                    data-qaid="offersList_alertdialog"
                    showDialog={showDialog}
                    body={deleteBodyMessage}
                    heading={deleteHeading}
                    isDeleteError={isDeleteError}
                    handleClose={handleClose}
                    showExtra = {false}
                    />        
                <Message
                    open={alertData.open}
                    severity={alertData.severity}
                    msg={alertData.msg}
                    handleClose={() => console.log()}
                />
            </>

        </RightDrawer >
    )
}
const Message = ({ open, handleClose, severity, msg }) => {
    return (
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          <Typography variant='h5'>{msg}</Typography>
        </Alert>
      </Snackbar>
    );
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

export default EditPrinter;