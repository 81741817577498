import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ContextMenuIcon from './SVG/ContextMenu';
import colors from '../styles/colors'
import { Grid, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { AvenirBlackH4 } from '../utils/text'
import styles from "../styles/index";

const StyledMenu = withStyles({
    paper: {
        // border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={5}
        width={'100%'}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        marginThreshold={40}
        PaperProps={{
            style: {
                width: "18%",
                left: 0,
                right: 100,
                marginTop: 10
            }
        }}
        // style={{marginRight:'20%'}}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        // '&:focus': {
        //     backgroundColor: '#C8E6F1',
        //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        //         color: colors.navyText,
        //     },
        // },
        '&:hover': {
            backgroundColor: '#C8E6F1',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: colors.navyText,
            },
        }

    },
    // .MuiListItemIcon-root {
    //     color: rgba(0, 0, 0, 0.54);
    //     display: inline-flex;
    //     min-width: 30px;
    //     flex-shrink: 0;
    // }
}))(MenuItem);

export default function ButtonMenu({ menuItems = [], label = "", callBack = null, btnDisable = false, showLines = false  }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const style = styles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCallback = (id) => {
        handleClose()
        callBack(id)
    }
    return (
        <div>
            <Button
                id="basic-button"
                className={style.buttonClass}
                style={{
                    backgroundColor: btnDisable ? '#C3C3C3':'#00B6C9',
                    color: 'black',
                    marginRight: 10,
                }} disabled={btnDisable} 
                variant='contained' 
                onClick={handleClick}
            >
                <AvenirBlackH4  label={label} />
            </Button>
            <StyledMenu
                style={{ marginRight: 50 }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {menuItems.length > 0 ?
                    menuItems.map((item, index3) => (
                        <StyledMenuItem onClick={() => { handleCallback(item.id) }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', borderBottom: showLines && index3 !== menuItems.length - 1 ? '1px solid #c3c3c3' : 'none' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                <ListItemIcon className={style.icon}>
                                    <item.icon width={20} height={20} >
                                    </item.icon>
                                </ListItemIcon>
                                <ListItemText primary={item.name} style={{ color: colors.navyText, fontSize: 12, fontFamily: 'AvenirLT', marginRight: 8 }} />
                            </div>

                        </StyledMenuItem>
                    )) :
                    <Grid>
                    </Grid>
                }
            </StyledMenu>
        </div>
    );
}
