import instance from "./axiosInstance";
import { ticket_customization_instance } from "./axiosInstance";
import urls, { insertId } from "./urls";

const fetchKitchens = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.kitchens).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_KITCHENS_SUCCESS", payload: response.data });
      }
    });
  };
};
const fetchPrinters = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.printers).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_PRINTERS_SUCCESS", payload: response.data });
      }
    });
  };
};

const createPrinter = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.printers, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const editPrinter = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.printers, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deletePrinters = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.printers, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchAssignKitchenPrinter = (params, callback) => {
  return (dispatch) => {
    instance.get(urls.settings.assign).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(response);
        dispatch({ type: "" });
      }
    });
  };
};

const createAssignKitchen = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.assign, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchMore = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.meta).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_MORE_SUCCESS", payload: response.data });
      }
    });
  };
};
const createMore = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.meta, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const EditMore = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.meta, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deleteMore = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.meta, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchShifts = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.shifts).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_SHIFTS_SUCCESS", payload: response.data });
      }
    });
  };
};
const createShifts = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.shifts, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const EditShifts = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.shifts, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deleteShifts = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.shifts, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchTaxes = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.taxes).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_TAXES_SUCCESS", payload: response.data });
      }
    });
  };
};
const createTaxes = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.taxesUrl, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const getTaxeDetails = (params, callback) => {
  console.log("params", params);
  return (dispatch) => {
    instance.get(urls.settings.taxesUrl, { params }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(response.data);
        dispatch({ type: "" });
      }
    });
  };
};
const fetchAssignTaxes = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.assignTaxUrl).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_ASSIGN_TAXES_SUCCESS", payload: response.data });
      }
    });
  };
};
const createAssignTaxes = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.assignTaxUrl, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const EditTaxes = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.taxesUrl, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};


const fetchPayments = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.payment).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_PAYMENT_SUCCESS", payload: response.data });
      }
    });
  };
};

const createPaymets = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.payment, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const EditPayments = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.payment, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deletePayment = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.payment, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchConfigPayments = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.config_payments).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "GET_CONFIG_PAYMENT_SUCCESS",
          payload: response.data,
        });
      }
    });
  };
};
const fetchOffers = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.offers).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_OFFERS_SUCCESS", payload: response.data });
      }
    });
  };
};
const createOffers = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.offers, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const EditOffers = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.offers, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deleteOffers = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.offers, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const fetchReservations = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.reservations).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_RESERVATIONS_SUCCESS", payload: response.data });
      }
    });
  };
};
const createReservation = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.reservations, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const fetchOrdertTypeDelivery = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.order_delivery_types).then((response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("response.dataresponse.data", response.data);
        dispatch({ type: "GET_ORDER_DELIVERY", payload: response.data });
      }
    });
  };
};

const verifyRest = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.activation.verify, params)
      .then((response) => {
        console.log(" verifyRest response=>", response);
        callback(true, response);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err.status);
      });
  };
};
const activateRest = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.activation.active, params)
      .then((response) => {
        console.log(response);
        callback(true);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err.status);
      });
  };
};
const mfaActivation = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.activation.mfa_activation, params)
      .then((response) => {
        console.log("response==>response.data", response.data);
        localStorage.setItem("session_token", response?.data?.session_token);
        callback(true);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err.status);
      });
  };
};
const resendMfaCode = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.activation.mfa_resend, params)
      .then((response) => {
        console.log(response);
        callback(true);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err.status);
      });
  };
};
const fetchDeliveryOptions = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.deliveryOptions).then((response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("response.dataresponse.data", response.data);
        dispatch({ type: "GET_DELIVERY_OPTIONS", payload: response.data });
      }
    });
  };
};
const createDeliveryOptions = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.deliveryOptions, params)
      .then((response) => {
        console.log(response);
        callback(true);
      })
      .catch((err) => {
        console.log(err);
        callback(false, err.status);
      });
  };
};
const editDeliveryOptions = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.deliveryOptions, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};
const deleteDeliveryOptions = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.deliveryOptions, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const fetchRemeberMe = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.remember_me).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_REMEMBER_ME_SUCCESS", payload: response.data });
      }
    });
  };
};

const postRememberMe = (params) => {
  return (dispatch) => {
    instance
      .put(urls.settings.remember_me, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          //   callback(true);
          dispatch({ type: "" });
          dispatch(fetchRemeberMe());
        }
      })
      .catch((err) => {
        // callback(false);
        console.log(err);
      });
  };
};

const fetchTermial = (params) => {
  return (dispatch) => {
    instance.get(urls.settings.terminal).then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: "GET_TERMINAL_SUCCESS", payload: response.data });
      }
    });
  };
};

const createTerminal = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.terminal, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const deleteTerminal = (cred, callback) => {
  return (dispatch) => {
    instance
      .delete(urls.settings.terminal, { data: cred })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const editTerminal = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.terminal, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const disconnectTerminal = (params, callback) => {
  return (dispatch) => {
    instance.post(urls.settings.disconnect_terminal, params).then((response) => {
      console.log(response);
      if (response.status === 200) {
        callback(true);
      }
    })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  }
}
const fetchEodSettings = () => {
  return (dispatch) => {
    instance.get(urls.settings.eod_settings).then((response) => {
      if (response.status === 200) {
        dispatch({ type: "GET_EOD_SETTINGS", payload: response.data });
      }
    });
  };
};

const editEodSettings = (params, callback) => {
  return (dispatch) => {
    instance
      .put(urls.settings.eod_settings, params)
      .then((response) => {
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
      });
  };
};

const fetchEodReports = () => {
  return (dispatch) => {
    instance.get(urls.settings.eod_reports).then((response) => {
      console.log("eod_reports", response);
      if (response.status === 200) {
        dispatch({ type: "GET_EOD_REPORTS", payload: response.data.data });
      }
    });
  };
};

const postEodReportSettings = (params, callback) => {
  return (dispatch) => {
    instance
      .post(urls.settings.eod_batch_update_reports, params)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          callback(true);
          dispatch({ type: "" });
        }
      })
      .catch((err) => {
        callback(false);
        console.log(err);
      });
  };
};

const fetchKitchenTicketSettings = (id) => {
  return (dispatch) => {
    ticket_customization_instance
      .get(insertId(urls.ticket_customization.kitchen_ticket, id))
      .then((response) => {
        console.log({ chit_settings: response });
        if (response.status === 200 || response.status === 304) {
          dispatch({
            type: "GET_KITCHEN_TICKET_SETTINGS",
            payload: response.data.settings,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const putKitchenTicketSettings = (id, params, callback) => {
  return (dispatch) => {
    ticket_customization_instance
      .put(insertId(urls.ticket_customization.kitchen_ticket, id), params)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "" });
          callback(true);
        }
      })
      .catch((err) => {
        console.log(err);
        callback(false);
      });
  };
};

const fetchGuestCheckSettings = (id) => {
  return (dispatch) => {
    ticket_customization_instance
      .get(insertId(urls.ticket_customization.customer_receipt, id))
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          dispatch({
            type: "GET_GUEST_CHECK_SETTINGS",
            payload: response.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export {
  fetchKitchens,
  fetchPrinters,
  editPrinter,
  createPrinter,
  deletePrinters,
  fetchMore,
  createMore,
  EditMore,
  deleteMore,
  fetchShifts,
  createShifts,
  EditShifts,
  deleteShifts,
  fetchAssignKitchenPrinter,
  createAssignKitchen,
  createTaxes,
  getTaxeDetails,
  EditTaxes,
  fetchAssignTaxes,
  createAssignTaxes,
  fetchPayments,
  deletePayment,
  createPaymets,
  EditPayments,
  fetchConfigPayments,
  fetchOffers,
  createOffers,
  EditOffers,
  deleteOffers,
  fetchReservations,
  createReservation,
  fetchOrdertTypeDelivery,
  verifyRest,
  activateRest,
  fetchDeliveryOptions,
  createDeliveryOptions,
  editDeliveryOptions,
  deleteDeliveryOptions,
  mfaActivation,
  resendMfaCode,
  fetchRemeberMe,
  postRememberMe,
  fetchTermial,
  createTerminal,
  deleteTerminal,
  editTerminal,
  disconnectTerminal,
  fetchEodSettings,
  fetchEodReports,
  editEodSettings,
  postEodReportSettings,
  fetchKitchenTicketSettings,
  putKitchenTicketSettings,
  fetchGuestCheckSettings,
};
