import React, { useContext, useEffect, useState } from 'react';
import RightDrawer from '../../../../components/RightDrawer';
import { Grid, makeStyles, Button, Box } from '@material-ui/core';
import MenuBookIcon from '../../../../components/SVG/Menu';
import CircleClose from '../../../../components/SVG/CircleClose';
import { ConvertPrice, parseToFloat } from '../../../../utils/util';
import MyContext from '../../../../Provider/MyContext';
import Basic from './Basic';
import Advanced from './Advanced';
import SnackBar from '../../../../components/SnackBar';
import DeleteIcon from '../../../../components/SVG/Delete';
import CopyIcon from '../../../../components/SVG/Copy';
import { useSelector, useDispatch } from 'react-redux';
import AlertDialog from '../../../../components/Dialog';
import {
  fetchItemInfoById,
  createItemsByCatId,
  editItemsByCatId,
  deleteItemsByCatId,
  copyItemById,
} from '../../../../redux/actions';
import { AvenirBlackH4, H4 } from '../../../../utils/text';
const useStyles = makeStyles({
  inputText: {
    fontSize: 14,
    color: '#051D33',
    fontFamily: 'AvenirLT',
  },
  h: {
    height: '100%',
  },
});
const CreateItem = ({
  open,
  id,
  callback,
  onClose,
  itemId,
  deleteItemCallback,
  copyItemCallback,
  isAlcohol,
}) => {
  const Context = useContext(MyContext);

  const dispatch = useDispatch();
  const [isBasic, toggleBasic] = useState(true);
  const [snackbar, setSnackbar] = useState(null);
  const [basicInfo, setbasicInfo] = useState({
    name: '',
    price: '',
    desc: '',
    image: null,
    isActive: true,
    isAlcohol: false,
    addedModifiers: [],
    stock: true,
  });

  const [advInfo, setAdvInfo] = useState({
    servings: [
      {
        name: 'Base',
        pricings: [],
      },
    ],
    modifiers: [],
    tax_mode: 'category-tax',
    taxp_id: null,
    tax_inclusive: false,
  });
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const addonsList = useSelector((state) => state.menu.addons);
  const classes = useStyles();
  const [showDeleteDialog, toggleDeleteDialog] = useState(false);
  const [showCopyDialog, toggleCopyDialog] = useState(false);
  const [isEdit, toggleIsEdit] = useState(true);
  const [alreadyExistsErr, setAlreadyExistsErr] = useState(false);
  const [taxProfileIdErr, setTaxProfileIdErr] = useState(false);
  const items = useSelector((state) => state.menu.category_items);

  const { servings } = advInfo;
  const onSave = () => {
    const { name, price, pre_name } = basicInfo;
    const mappedItems = items.map((item) => {
      return item.name.toLowerCase() != name.toLowerCase() ? true : false;
    });
    //const validate = mappedItems.every(v => v)
    const { servings } = advInfo;
    console.log('SERVINGS LENGTH', servings.length, servings);
    let validate = items.findIndex((e) => e.name === name);
    if (validate == -1 || name == pre_name) {
      setAlreadyExistsErr(false);
      if (name == '') {
        setSnackbar({ message: 'Please enter name', severity: 'error' });
      } else if (price == '') {
        setSnackbar({ message: 'Please enter price', severity: 'error' });
      } else if (selectedOrderTypes.length == 0) {
        setSnackbar({
          message: 'Select atleast one order type',
          severity: 'error',
        });
      } else if (servings.length == 0) {
        setSnackbar({
          message: 'Item should have at least one serving',
          severity: 'error',
        });
      } else if (!advInfo.taxp_id && advInfo.tax_mode === 'custom-tax') {
        setTaxProfileIdErr(true);
        setSnackbar({
          message: 'Tax profile need to be selected',
          severity: 'error',
        });
      } else {
        const obj = {
          cat_id: id,
          g_availability: basicInfo.isActive,
          dietary_attributes: null,
          is_out_of_stock: basicInfo.stock == 0 ? 1 : 0,
          name: basicInfo.name.trim(),
          image_url: basicInfo.image,
          base_price: parseToFloat(basicInfo.price),
          description: basicInfo.desc,
          isVariablePackagingCharge: false,
          serving: servings.map((a) => a.name),
          pricing_type: 'variable',
          comments: [],
          item_type: 'Food',
          tax_mode: advInfo.tax_inclusive
            ? 'tax-inclusive'
            : advInfo.tax_mode === 'tax-free'
            ? 'tax-free'
            : 'standard',
          taxp_id: advInfo.taxp_id,
        };

        let prices_availability = [];

        selectedOrderTypes.map((s) => {
          let prices = [];
          let pkg = [];
          servings.map((ser) => {
            let ss = ser.pricings.find((p) => p.otype_id == s);
            if (ss) {
              prices.push(
                parseToFloat(ss.price) + parseToFloat(basicInfo.price)
              );
              pkg.push(0);
            }
          });
          prices_availability.push({
            otype_id: s,
            availability: true,
            packaging_charges: pkg,
            price: prices,
          });
        });
        let addons = [];
        if (
          basicInfo.addedModifiers.length > 0 ||
          advInfo.modifiers.length > 0
        ) {
          let mandatoryAddons = servings.map((s) => {
            let maddons = basicInfo.addedModifiers.map((modifier) => {
              return {
                addon_cat_id: modifier.addon_cat_id,
                allow_multi_select: modifier.isAllowMultiSelect == true ? 1 : 0,
                minimum_select: modifier.min,
                maximum_select: modifier.max,
                allow_multi_quantity:
                  modifier.isAllowMultiQuantity == true ? 1 : 0,
                minimum_quantity: modifier.minQty == null ? 1 : modifier.minQty,
                maximum_quantity: modifier.maxQty == null ? 1 : modifier.maxQty,
                is_mandatory: true,
                addon_items: modifier.addons.map((ad) => {
                  return {
                    addon_id: ad.addon_item_id,
                    addon_item_name: ad.addon_item_name,
                    price: ad.price ? parseToFloat(ad.price) : 0,
                  };
                }),
              };
            });
            let oaddons = advInfo.modifiers.map((modifier) => {
              return {
                addon_cat_id: modifier.addon_cat_id,
                allow_multi_select: modifier.isAllowMultiSelect == true ? 1 : 0,
                minimum_select: modifier.min,
                maximum_select: modifier.max,
                allow_multi_quantity:
                  modifier.isAllowMultiQuantity == true ? 1 : 0,
                minimum_quantity: modifier.minQty == null ? 1 : modifier.minQty,
                maximum_quantity: modifier.maxQty == null ? 1 : modifier.maxQty,
                is_mandatory: false,
                addon_items: modifier.addons.map((ad) => {
                  return {
                    addon_id: ad.addon_item_id,
                    addon_item_name: ad.addon_item_name,
                    price: ad.price ? parseToFloat(ad.price) : 0,
                  };
                }),
              };
            });
            return {
              serving: s.name,
              addons: maddons.concat(oaddons),
            };
          });
          addons = addons.concat(mandatoryAddons);
        }

        obj['addons'] = addons;
        obj['prices_availability'] = prices_availability;

        if (itemId) {
          obj['is_alcohol'] = basicInfo.isAlcohol ? 'Alcohol' : 'Food';
          obj['item_id'] = itemId;
          dispatch(editItemsByCatId(obj, onCreateCallback));
        } else {
          obj['item_type'] = isAlcohol ? 'Alcohol' : 'Food';
          dispatch(createItemsByCatId(obj, onCreateCallback));
        }
      }
    } else {
      setAlreadyExistsErr(true);
    }

    // callback();
  };
  const onCreateCallback = (res) => {
    if (res) {
      callback();
    }
  };
  useEffect(() => {
    if (itemId) {
      toggleIsEdit(false);
      dispatch(fetchItemInfoById({ item_id: itemId }, fetchItemByIdCallBack));
    } else {
      let otypeIds = ordertypes.map((otype) => otype.otype_id);
      setSelectedOrderTypes(otypeIds);
    }
  }, [itemId, ordertypes]);
  const fetchItemByIdCallBack = (res) => {
    let obj = {
      name: res.name,
      pre_name: res.name,
      price: ConvertPrice(res.base_price) || '',
      desc: res.description,
      image: res.img_url,
      isActive: res.g_availability == 1,
      stock: res.is_out_of_stock == 0,
      isAlcohol: res.is_alcohol == 1,
      addedModifiers: [],
    };
    let selOtypes = [];
    if (res?.prices_availability?.length > 0) {
      res.prices_availability.map((p) => {
        if (p.availability) {
          selOtypes.push(p.otype_id);
        }
      });
    }
    const servings = res.serving.map((serving, index) => {
      return {
        name: serving.name,
        pricings: res.prices_availability.map((p) => {
          return {
            otype_id: p.otype_id,
            otype_name: p.otype_name,
            price: p.price[index] - res.base_price,
          };
        }),
      };
    });
    let maddon = [];
    let oaddon = [];
    if (res.addons.length > 0) {
      let addon = res.addons[0];
      addon.addons.map((ad) => {
        let add = addonsList.find((a) => a.addon_cat_id == ad.addon_cat_id);
        let obj = {
          addon_cat_id: ad.addon_cat_id,
          addon_cat_name: add.addon_cat_name,
          min: ad.minimum_select,
          max: ad.maximum_select,
          isAllowMultiSelect: ad.allow_multi_select,
          minQty: ad.minimum_quantity,
          maxQty: ad.maximum_quantity,
          isAllowMultiQuantity: ad.allow_multi_quantity,
          addons: ad.addon_items.map((adItem) => {
            let addonItem = {};
            if (typeof adItem == 'number') {
              addonItem = add.addons.find(
                (adonItem) => adonItem.addon_item_id == adItem
              );
              return {
                addon_item_id: adItem,
                price: addonItem.addon_item_price,
                addon_item_name: addonItem.addon_item_name,
              };
            } else {
              addonItem = add.addons.find(
                (adonItem) => adonItem.addon_item_id == adItem.addon_id
              );
              return {
                addon_item_id: adItem.addon_id,
                price: adItem.price,
                addon_item_name: addonItem.addon_item_name,
              };
            }
          }),
        };
        if (ad.minimum_select == 0) {
          oaddon.push(obj);
        } else {
          maddon.push(obj);
        }
      });
    }
    obj['addedModifiers'] = maddon;

    setSelectedOrderTypes(selOtypes);
    let taxObj;
    if (res.tax_mode === 'tax-inclusive') {
      taxObj = {
        servings: servings,
        modifiers: oaddon,
        tax_mode: res.taxp_id ? 'custom-tax' : 'category-tax',
        tax_inclusive: true,
        taxp_id: res.taxp_id,
      };
    } else {
      if (res.tax_mode === 'tax-free') {
        taxObj = {
          servings: servings,
          modifiers: oaddon,
          tax_mode: 'tax-free',
          tax_inclusive: false,
          taxp_id: res.taxp_id,
        };
      } else {
        taxObj = {
          servings: servings,
          modifiers: oaddon,
          tax_mode: res.taxp_id ? 'custom-tax' : 'category-tax',
          tax_inclusive: false,
          taxp_id: res.taxp_id,
        };
      }
    }
    setAdvInfo(taxObj);
    toggleIsEdit(true);
    setbasicInfo(obj);
  };
  useEffect(() => {
    if (isEdit) {
      let newServings = servings.map((s) => {
        let { pricings, name } = s;
        let t = [];
        selectedOrderTypes.map((otype) => {
          let d;
          if (pricings) d = pricings.find((p) => p.otype_id == otype);
          if (d) {
            t.push(d);
          } else {
            let orderType = ordertypes.find((o) => o.otype_id == otype);
            t.push({
              otype_id: otype,
              price: 0,
              otype_name: orderType?.otype_name,
            });
          }
        });
        return { name, pricings: t };
      });
      setAdvInfo({ ...advInfo, servings: newServings });
    }
  }, [selectedOrderTypes]);
  const handleDeleteClose = (e) => {
    if (e) {
      dispatch(deleteItemsByCatId([itemId], onDelete));
    } else {
      toggleDeleteDialog(false);
    }
  };
  const onDelete = (res) => {
    toggleDeleteDialog(false);
    if (res) deleteItemCallback();
  };
  const handleCopyClose = (e) => {
    console.log('You clicked', e);
    if (e) {
      dispatch(
        copyItemById({ item_id: itemId }, (s) => {
          toggleCopyDialog(false);
          if (s) {
            copyItemCallback();
          } else {
            setSnackbar({
              message: 'Failed to copy the item',
              severity: 'error',
            });
          }
        })
      );
    } else {
      toggleCopyDialog(false);
    }
  };

  return (
    <RightDrawer open={open} padding={1}>
      <Grid
        container
        style={{
          minWidth: 500,
          height: '100%',
          position: 'relative',
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        <Grid container style={{ width: '100%' }}>
          <Grid
            container
            data-qaid='create_item_container'
            style={{ height: 60, padding: '0px 24px' }}
            justify='space-between'
            alignItems='center'>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-start'
                alignItems='center'
                className={classes.h}>
                <MenuBookIcon
                  data-qaid='assignkitchen_setting_icon'
                  style={{ marginRight: 10 }}
                  width={22.5}
                  height={24}
                  color='#707272'
                />
                <H4
                  data-qaid='assignkitchen_settings'
                  label={Context.langData.menu}
                  style={{ color: '#707272' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-end'
                alignItems='center'
                className={classes.h}>
                <CircleClose
                  data-qaid='assignkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <BtnGrp isBasic={isBasic} toggleBasic={toggleBasic} />
          <div
            className='show_scroll'
            style={{
              overflow: 'auto',
              height: window.innerHeight - 235,
              width: '100%',
            }}>
            <Box display={isBasic ? 'contents' : 'none'}>
              <Basic
                ordertypes={ordertypes}
                selectedOrderTypes={selectedOrderTypes}
                setSelectedOrderTypes={setSelectedOrderTypes}
                basicInfo={basicInfo}
                isEdit={itemId ? true : false}
                allModifiers={[
                  ...basicInfo.addedModifiers,
                  ...advInfo.modifiers,
                ]}
                setbasicInfo={setbasicInfo}
                setAlreadyExistsErr={alreadyExistsErr}
                advInfo={advInfo}
                setAdvInfo={setAdvInfo}
                taxProfileIdErr={taxProfileIdErr}
              />
            </Box>
            <Box display={!isBasic ? 'contents' : 'none'}>
              <Advanced
                advInfo={advInfo}
                basePrice={basicInfo.price}
                setAdvInfo={setAdvInfo}
                isEdit={itemId ? true : false}
                allModifiers={[
                  ...basicInfo.addedModifiers,
                  ...advInfo.modifiers,
                ]}
                selectedOrderTypes={selectedOrderTypes}
                ordertypes={ordertypes}
              />
            </Box>
            {/* <div style={{ display: 'inline-block', height: 100 }} /> */}
          </div>
        </Grid>
        <Grid
          container
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            height: 60,
            borderTop: '1px solid #EEE',
            justifyContent: 'flex-end',
            bottom: 0,
            left: 0,
            alignItems: 'center',
          }}>
          <Grid>
            <Grid container justify='flex-end'>
              {itemId && (
                <>
                  <Button
                    startIcon={<DeleteIcon />}
                    style={{ marginRight: 10 }}
                    onClick={() => toggleDeleteDialog(true)}>
                    <AvenirBlackH4
                      label='Delete'
                      style={{ color: '#004C60' }}
                    />
                  </Button>
                  <Button
                    startIcon={<CopyIcon />}
                    style={{ marginRight: 10 }}
                    onClick={() => toggleCopyDialog(true)}>
                    <AvenirBlackH4
                      label='Copy'
                      style={{ textDecoration: 'underline', color: '#004C60' }}
                    />
                  </Button>
                </>
              )}
              <Button
                variant='contained'
                style={{
                  border: '2px solid #051D33',
                  height: 40,
                  width: 103,
                  backgroundColor: '#FFF',
                  marginRight: 20,
                }}
                onClick={onClose}>
                <AvenirBlackH4 label='Cancel' />
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: '#FFB600',
                  height: 40,
                  width: 90,
                  marginRight: 20,
                }}
                onClick={onSave}>
                <AvenirBlackH4 label='Save' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {showDeleteDialog && (
        <AlertDialog
          showDialog={showDeleteDialog}
          body={'Are you sure you want to delete this item from your menu?'}
          heading={'Delete item'}
          IsError={false}
          showExtra={false}
          handleClose={handleDeleteClose}
        />
      )}
      {showCopyDialog && (
        <AlertDialog
          showDialog={showCopyDialog}
          body={'Are you sure you want to copy this item?'}
          heading={'Copy item'}
          IsError={false}
          showExtra={false}
          handleClose={handleCopyClose}
        />
      )}
    </RightDrawer>
  );
};

const BtnGrp = ({ isBasic, toggleBasic }) => {
  return (
    <Grid container direction='row' style={{ padding: '0px 24px' }}>
      <Button
        style={{
          height: 52,
          backgroundColor: isBasic ? '#051D33' : '#EEEEEE',
          marginRight: 1,
          borderRadius: 0,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          width: '49%',
        }}
        onClick={() => toggleBasic(true)}>
        <AvenirBlackH4
          data-qaid='settings_button_label'
          label={'Basic'}
          style={{ color: isBasic ? '#FFF' : '#051D33' }}
        />
      </Button>
      <Button
        style={{
          height: 52,
          backgroundColor: !isBasic ? '#051D33' : '#EEEEEE',

          borderRadius: 0,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          width: '50%',
        }}
        onClick={() => toggleBasic(false)}>
        <AvenirBlackH4
          data-qaid='settings_button_label'
          label={'Advanced'}
          style={{ color: !isBasic ? '#FFF' : '#051D33' }}
        />
      </Button>
    </Grid>
  );
};
export default CreateItem;
