import _ from 'lodash';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CenterModal from '../../../components/CenterModal';
import Position from '../../../components/Position';
import MyContext from '../../../Provider/MyContext';
import { createCatePosition } from '../../../redux/actions';
import styles from '../../../styles/index';
const SetPositionItem = ({ open, callback, onClose, itemsList }) => {
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const CloseCallback = (value, data) => {
    console.log('value', value, 'data', data);
    if (value) {
      if (data.length > 0) {
        var array = [];
        data.map((item, index) => {
          var obj = [];
          obj.push(item.cat_id);
          obj.push(index);
          array.push(obj);
        });
        dispatch(createCatePosition(array, callback));
      }
    } else {
      onClose();
    }
  };
  return (
    <CenterModal data-qaid='setposition_centarmodal' open={open}>
      <Position
        data-qaid='setposition_position'
        data={itemsList}
        callBack={CloseCallback}></Position>
    </CenterModal>
  );
};

export default SetPositionItem;
