import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { H4, AvenirBlackH3 } from '../utils/text';
import MyContext from '../Provider/MyContext';
import { LineIcon } from './SVG';
import moment from 'moment';

const PositionItemCard = ({ name, status, date }) => {
  const Context = useContext(MyContext);
  const getDateFormat = (timestamp) => {
    var val = moment.unix(timestamp).format('MMMM DD, YYYY');
    return val;
  };
  return (
    <Grid
      item
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid #C3C3C3`,
        borderRadius: '5px',
        padding: 10,
      }}>
      {/* <MenuIcon style={{ marginRight: 20 }} /> */}
      {name && (
        <AvenirBlackH3
          label={name || ''}
          style={{ marginRight: 16, flexBasis: '30%' }}
        />
      )}
      {status && (
        <AvenirBlackH3
          label={status || ''}
          style={{ marginRight: 16, flexBasis: '30%' }}
        />
      )}
      <LineIcon style={{ marginRight: 16 }} />
      {date && (
        <H4
          label={`${Context.langData.lastModified} ${
            getDateFormat(date) || ''
          }`}
          style={{ color: '#54575A', flexBasis: '30%' }}
        />
      )}
    </Grid>
  );
};

export default PositionItemCard;
