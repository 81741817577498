import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import RightDrawer from '../../../../components/RightDrawer';
import CircleClose from '../../../../components/SVG/CircleClose';
import MenuBookIcon from '../../../../components/SVG/Menu';
import { H4, AvenirBlackH4 } from '../../../../utils/text';
import MyContext from '../../../../Provider/MyContext';
import DeleteIcon from '../../../../components/SVG/Delete';
import CopyIcon from '../../../../components/SVG/Copy';
import { useSelector } from 'react-redux';
import Basic from './basic';
import EditSizes from './editSizes';
import EditCrusts from './editCrusts';
import EditCheese from './editCheese';
import EditSauce from './editSauce';
import EditToppings from './editToppings';
import AlertDialog from '../../../../components/Dialog';
import { useDispatch } from 'react-redux';
import Advanced from './advanced';
import { parseToFloat, ConvertPrice } from '../../../../utils/util';
import SnackBar from '../../../../components/SnackBar';
import {
  createItemsByCatId,
  fetchItemsByCatId,
  fetchItemInfoById,
  editItemsByCatId,
  deleteItemsByCatId,
  copyItemById,
} from '../../../../redux/actions';
const useStyles = makeStyles({
  inputText: {
    fontSize: 14,
    color: '#051D33',
    fontFamily: 'AvenirLT',
  },
  h: {
    height: '100%',
  },
});
const c = { byo_type_ingredient_id: -1 };
const PizzasModal = ({
  open,
  onClose,
  cat_id,
  item,
  itemId,
  callback,
  deleteItemCallback,
  copyItemCallback,
  editCallback,
}) => {
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const { pizzaConfig } = useSelector((state) => state.menu);
  const classes = useStyles();
  const Context = useContext(MyContext);
  const [isBasic, toggleBasic] = useState(true);
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [configItem, setConfigItem] = useState(c);
  const [bodyMessage, setBodyMessage] = useState(
    Context.langData.are_you_sure_you_want_to_delete_this_item
  );
  const [heading, setHeading] = useState(Context.langData.delete_item_header);
  const [isDisabled, toggleDisabled] = useState(true);
  const [basicInfo, setBasicInfo] = useState({
    name: '',
    price: '',
    isActive: true,
    ordertypes: [],
    desc: '',
    image: '',
    tax_mode: 'category-tax',
    taxp_id: null,
    tax_inclusive: false,
  });
  const [advanced, setAdvanced] = useState({
    half_and_half: [],
    variablePrice: [],
    attributes: [],
  });
  const [attributes, setAttr] = useState([
    { name: 'Sizes', id: 'size', sizes: [] },
    { name: 'Crust', id: 'crust', sizes: [] },
    {
      name: 'Sauce',
      id: 'sauce',
      sizes: [],
      custom: { light: false, extra: false },
    },
    { name: 'Cheese', id: 'cheese', sizes: [] },
    {
      name: 'Toppings',
      id: 'toppings',
      sizes: [],
      custom: { double: false, tripple: false },
      limit: { hasLimit: '0', limit: 1 },
    },
    { name: 'Special instruction', id: 'special', sizes: [] },
  ]);
  const [byoResponse, setResponse] = useState([]);
  const [config, setConfig] = useState([]);
  const [combo, setCombo] = useState(null);
  const fetchItemByIdCallBack = (res) => {
    let basicObjWithTaxDetails;
    if (res.tax_mode === 'tax-inclusive') {
      basicObjWithTaxDetails = {
        name: res.name,
        price: ConvertPrice(res.base_price),
        image: res.img_url,
        desc: res.description,
        isActive: res.g_availability == 1,
        ordertypes: ordertypes.map((ot) => ot.otype_id),
        tax_mode: res?.taxp_id ? 'custom-tax' : 'category-tax',
        tax_inclusive: true,
        taxp_id: res?.taxp_id,
      };
    } else {
      if (res.tax_mode === 'tax-free') {
        basicObjWithTaxDetails = {
          name: res.name,
          price: ConvertPrice(res.base_price),
          image: res.img_url,
          desc: res.description,
          isActive: res.g_availability == 1,
          ordertypes: ordertypes.map((ot) => ot.otype_id),
          tax_mode: 'tax-free',
          tax_inclusive: false,
          taxp_id: res?.taxp_id,
        };
      } else {
        basicObjWithTaxDetails = {
          name: res.name,
          price: ConvertPrice(res.base_price),
          image: res.img_url,
          desc: res.description,
          isActive: res.g_availability == 1,
          ordertypes: ordertypes.map((ot) => ot.otype_id),
          tax_mode: res.taxp_id ? 'custom-tax' : 'category-tax',
          tax_inclusive: false,
          taxp_id: res?.taxp_id,
        };
      }
    }
    setBasicInfo(basicObjWithTaxDetails);
    const pc = [...pizzaConfig];
    const byo = [...res.build_your_own];
    setResponse(byo);
    let cf = pc.map((p) => {
      let byoC = byo.find(
        (e) => e.byo_type_ingredient_id == p.byo_type_ingredient_id
      );
      let newC = { ...p };

      if (byoC) {
        let custom = [];
        if (byoC.byo_ingredient_customization) {
          custom = byoC.byo_ingredient_customization.map(
            (e) => e.byo_type_ingredient_customization_id
          );
        }
        newC = {
          ...p,
          allow_customization: byoC.allow_customization,
          ingredient_customization_selected: custom,
          byo_ingredients: byoC.byo_item_ingredients,
          allow_half_half: byoC.allow_half_half,
          multiple_selection_config: byoC.multiple_selection_config,
        };
      }

      return newC;
    });
    let half_and_half = byo
      .filter((e) => {
        if (e.allow_half_half) {
          return e;
        }
      })
      .map((e) => e.byo_type_ingredient_id);
    let variablePrice = res.prices_availability.map((e) => {
      let p = e.price[0] || 0;
      return {
        otype_id: e.otype_id,
        otype_name: e.otype_name,
        availability: e.availability,
        price: parseToFloat(p) - parseToFloat(res.base_price),
      };
    });
    setConfig(cf);
    setAdvanced({
      ...advanced,
      half_and_half,
      variablePrice,
      tax_mode: res.tax_mode,
    });
  };
  useEffect(() => {
    let dis = basicInfo.name;
    let cfg = [...config];
    cfg.map((c) => {
      if (c.byo_ingredients.length == 0) {
        dis = false;
      }
    });
    toggleDisabled(!dis);
  }, [config, basicInfo.name, basicInfo.price]);
  useEffect(() => {
    if (itemId) {
      dispatch(fetchItemInfoById({ item_id: itemId }, fetchItemByIdCallBack));
    }
  }, [itemId]);

  useEffect(() => {
    const pc = [...pizzaConfig];
    let half = [];
    pc.map((p) => {
      p['byo_ingredients'] = [];
      if (p.ingredient_customization.length > 0) {
        p['ingredient_customization_selected'] = [];
      }
      if (p.allow_multiple_choice) {
        p['multiple_selection_config'] = { minimum: 1, maximum: 1 };
      }
      if (p.allow_half_half) {
        half.push(p.byo_type_ingredient_id);
      }
    });
    setConfig(pc);
    setAdvanced({ ...advanced, half_and_half: half });
  }, [pizzaConfig]);
  useEffect(() => {
    const pc = [...config];
    pc.map((p) => {
      let index = advanced.half_and_half.findIndex(
        (e) => e == p.byo_type_ingredient_id
      );
      p['allow_half_half'] = index !== -1;
    });
    if (JSON.stringify(pc) !== JSON.stringify(config)) setConfig(pc);
  }, [advanced.half_and_half, config]);
  useEffect(() => {
    const otypes = ordertypes.map((ot) => ot.otype_id);
    setBasicInfo({ ...basicInfo, ordertypes: otypes });
  }, [ordertypes]);
  useEffect(() => {
    let priceAvailability = [];
    ordertypes.map((ot) => {
      let index = basicInfo.ordertypes.findIndex((e) => e == ot.otype_id);
      let adIndex = advanced.variablePrice.findIndex(
        (e) => e.otype_id == ot.otype_id
      );
      if (index !== -1) {
        priceAvailability.push({
          otype_id: ot.otype_id,
          otype_name: ot.otype_name,
          availability: true,
          price: adIndex !== -1 ? advanced.variablePrice[adIndex].price : '',
        });
      }
    });
    if (
      JSON.stringify(advanced.variablePrice) !==
      JSON.stringify(priceAvailability)
    )
      setAdvanced({
        ...advanced,
        variablePrice: priceAvailability,
      });
  }, [ordertypes, basicInfo.ordertypes, advanced.variablePrice]);
  const onEditAttr = (item) => {
    setConfigItem(item);
  };
  const onSaveAttrs = (data) => {
    const index = config.findIndex(
      (e) => e.byo_type_ingredient_id == data.byo_type_ingredient_id
    );
    setConfig([
      ...config.slice(0, index),
      { ...data },
      ...config.slice(index + 1),
    ]);
    setConfigItem(c);
  };
  const onSavePizza = () => {
    let priceAvailability = advanced.variablePrice.map((ot) => {
      return {
        otype_id: ot.otype_id,
        availability: true,
        packaging_charges: [0],
        price: [
          ot.price
            ? Math.round(
                parseToFloat(ot.price + parseToFloat(basicInfo.price)) * 100
              ) / 100
            : parseToFloat(basicInfo.price),
        ],
      };
    });
    if (!basicInfo.taxp_id && basicInfo.tax_mode === 'custom-tax') {
      // setTaxProfileIdErr(true);
      setSnackbar({
        message: 'Tax profile need to be selected',
        severity: 'error',
      });
    } else {
      const obj = {
        cat_id: cat_id,
        item_id: itemId,
        g_availability: basicInfo.isActive,
        is_alcohol: 0,
        description: basicInfo.desc,
        image_url: basicInfo.image || '',
        name: basicInfo.name,
        base_price: parseToFloat(basicInfo.price),
        prices_availability: priceAvailability,
        dietary: advanced.dietary,
        dietary_attributes: null,
        addons: [],
        isVariablePackagingCharge: false,
        serving: ['base'],
        pricing_type: 'simple',
        comments: [],
        item_type: 'byo',
        tax_mode: basicInfo.tax_inclusive
          ? 'tax-inclusive'
          : basicInfo.tax_mode === 'tax-free'
          ? 'tax-free'
          : 'standard',
        taxp_id: basicInfo.taxp_id,
      };
      let build_your_own = [];
      config.map((c) => {
        c.byo_ingredients.map((bi) => {
          if (bi.price == '') {
            bi.price = 0;
          } else {
            bi.price = Math.round(parseToFloat(bi.price) * 100) / 100;
          }
        });
        build_your_own.push(getBuildYourOwn(c));
      });
      obj['build_your_own'] = build_your_own;
      if (itemId) {
        const custom = build_your_own.map((byo) => {
          let c = byoResponse.find(
            (e) => e.byo_type_ingredient_id == byo.byo_type_ingredient_id
          );
          if (c) {
            if (byo.ingredient_customization) {
              const cust = byo.ingredient_customization.map((bic) => {
                let cus = c.byo_ingredient_customization.find(
                  (e) => e.byo_type_ingredient_customization_id == bic
                );
                if (cus) {
                  return cus;
                } else {
                  return { byo_type_ingredient_customization_id: bic };
                }
              });
              byo.ingredient_customization = cust;
            }
          } else {
            byo.ingredient_customization = [];
          }
        });
        dispatch(editItemsByCatId(obj, onEditCallback));
      } else {
        dispatch(createItemsByCatId(obj, onCreateCallback));
      }
    }
  };
  const onEditCallback = (res) => {
    if (res) {
      editCallback();
    }
    dispatch(fetchItemsByCatId({ category_id: cat_id }));
    onClose();
  };
  const onCreateCallback = (res) => {
    if (res) {
      callback();
    }
    dispatch(fetchItemsByCatId({ category_id: cat_id }));
    onClose();
  };
  const getBuildYourOwn = (c) => {
    const {
      byo_type_ingredient_id,
      allow_half_half,
      allow_multiple_choice,
      byo_ingredients,
      allow_customization,
      multiple_selection_config,
    } = c;
    let obj = {
      byo_type_ingredient_id,
      allow_half_half: allow_half_half ? 1 : 0,
      allow_multiple_selection: allow_multiple_choice ? 1 : 0,
      byo_item_ingredients: byo_ingredients,
      allow_customization: allow_customization ? 1 : 0,
      multiple_selection_config,
      ingredient_customization: c.ingredient_customization_selected || [],
    };
    return obj;
  };
  const commonCallback = () => {
    fetchItemsByCatId({ cat_id: cat_id });
    onClose();
  };
  const deleteItem = (res) => {
    // if (itemId)
    //   dispatch(deleteItemsByCatId([itemId], onDelete))
    if (itemId) setShowDialog(false);
    if (res) {
      deleteItemCallback();
    }
    commonCallback();
  };
  const onDelete = (res) => {
    if (itemId) setShowDialog(true);
    // if (res) {
    //   fetchItemsByCatId({ cat_id: cat_id })
    //   onClose()
    // }
  };
  const handleClose = (val) => {
    if (val) {
      dispatch(deleteItemsByCatId([itemId], deleteItem));
    }
    setShowDialog(false);
  };
  const deleteCallback = (isSuccess) => {
    fetchItemsByCatId({ cat_id: cat_id });
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.item_delete_succ,
        severity: 'success',
      });
    } else {
      setSnackbar({
        message: Context.langData.item_delete_failed,
        severity: 'error',
      });
    }
    onClose();
  };
  const onCopy = () => {
    if (itemId)
      dispatch(
        copyItemById(
          {
            item_id: itemId,
          },
          (res) => {
            if (res) {
              copyItemCallback();
              fetchItemsByCatId({ cat_id: cat_id });
              onClose();
            }
          }
        )
      );
  };
  const show = false;
  return (
    <RightDrawer open={open} onClose={onClose}>
      <Grid
        container
        style={{
          minWidth: 500,
          maxWidth: 500,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        <Grid container style={{ width: '100%' }}>
          <Grid
            container
            data-qaid='create_item_container'
            style={{ height: 60, padding: '0px 24px' }}
            justify='space-between'
            alignItems='center'>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-start'
                alignItems='center'
                className={classes.h}>
                <MenuBookIcon
                  data-qaid='assignkitchen_setting_icon'
                  style={{ marginRight: 10 }}
                  width={22.5}
                  height={24}
                  color='#707272'
                />
                <H4
                  data-qaid='assignkitchen_settings'
                  label={Context.langData.menu}
                  style={{ color: '#707272' }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-end'
                alignItems='center'
                className={classes.h}>
                <CircleClose
                  data-qaid='assignkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <BtnGrp isBasic={isBasic} toggleBasic={toggleBasic} />
          <Grid
            container
            style={{
              height: window.innerHeight - 190,
              overflow: 'auto',
              paddingBottom: 80,
            }}>
            {isBasic ? (
              <Basic
                basicInfo={basicInfo}
                ordertypes={ordertypes}
                attributes={attributes}
                pizzaConfig={config}
                onEditAttr={onEditAttr}
                setBasicInfo={(info) => setBasicInfo(info)}
                setBasicInfoTax={setBasicInfo}
              />
            ) : (
              <Advanced
                advanced={advanced}
                setAdvanced={setAdvanced}
                ordertypes={ordertypes}
              />
            )}
          </Grid>
          <Grid
            container
            style={{
              position: 'absolute',
              height: 60,
              backgroundColor: 'white',
              borderTop: '1px solid #EEE',
              justifyContent: 'flex-end',
              bottom: 0,
              left: 0,
              alignItems: 'center',
              zIndex: 999,
            }}>
            <Grid item xs={12} md={itemId ? 10 : 6}>
              <Grid container justify='flex-end'>
                {itemId && (
                  <>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => setShowDialog(true)}>
                      <AvenirBlackH4
                        label='Delete'
                        style={{ color: '#004C60' }}
                      />
                    </Button>
                    <Button onClick={onCopy} startIcon={<CopyIcon />}>
                      <AvenirBlackH4
                        label='Copy'
                        style={{
                          textDecoration: 'underline',
                          color: '#004C60',
                        }}
                      />
                    </Button>
                  </>
                )}
                <Button
                  style={{ border: '2px solid #707272' }}
                  onClick={onClose}>
                  <AvenirBlackH4 label='Cancel' />
                </Button>
                <Button
                  onClick={onSavePizza}
                  variant='contained'
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? '#c6c6c6' : '#FFB600',
                    marginLeft: 10,
                    marginRight: 10,
                  }}>
                  <AvenirBlackH4 label='Save' />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {configItem.byo_type_ingredient_name == 'Size' && (
          <EditSizes
            open={configItem.byo_type_ingredient_name == 'Size'}
            sizes={configItem}
            onSaveSizes={onSaveAttrs}
            onClose={() => setConfigItem(c)}
          />
        )}
        {configItem.byo_type_ingredient_name == 'Crust' && (
          <EditCrusts
            open={configItem.byo_type_ingredient_name == 'Crust'}
            sizes={configItem}
            onSaveSizes={onSaveAttrs}
            onClose={() => setConfigItem(c)}
          />
        )}
        {configItem.byo_type_ingredient_name == 'Cheese' && (
          <EditCheese
            open={configItem.byo_type_ingredient_name == 'Cheese'}
            sizes={configItem}
            onSaveSizes={onSaveAttrs}
            onClose={() => setConfigItem(c)}
          />
        )}
        {configItem.byo_type_ingredient_name == 'Sauce' && (
          <EditSauce
            open={configItem.byo_type_ingredient_name == 'Sauce'}
            sizes={configItem}
            onSaveSizes={onSaveAttrs}
            onClose={() => setConfigItem(c)}
          />
        )}
        {configItem.byo_type_ingredient_name == 'Toppings' && (
          <EditToppings
            open={configItem.byo_type_ingredient_name == 'Toppings'}
            sizes={configItem}
            onSaveSizes={onSaveAttrs}
            onClose={() => setConfigItem(c)}
          />
        )}
        {showDialog && (
          <AlertDialog
            data-qaid='categories_alertDialog'
            showDialog={showDialog}
            body={bodyMessage}
            heading={heading}
            IsError={false}
            showExtra={false}
            handleClose={handleClose}
          />
        )}
        {snackbar && (
          <SnackBar
            data-qaid='taxeslist_snackbar'
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        )}
      </Grid>
    </RightDrawer>
  );
};

const BtnGrp = ({ isBasic, toggleBasic }) => {
  return (
    <Grid container direction='row' style={{ padding: '0px 24px' }}>
      <Button
        style={{
          height: 52,
          backgroundColor: isBasic ? '#051D33' : '#EEEEEE',
          marginRight: 1,
          borderRadius: 0,
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          width: '49%',
        }}
        onClick={() => toggleBasic(true)}>
        <AvenirBlackH4
          data-qaid='settings_button_label'
          label={'Basic'}
          style={{ color: isBasic ? '#FFF' : '#051D33' }}
        />
      </Button>
      <Button
        style={{
          height: 52,
          backgroundColor: !isBasic ? '#051D33' : '#EEEEEE',

          borderRadius: 0,
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
          width: '50%',
        }}
        onClick={() => toggleBasic(false)}>
        <AvenirBlackH4
          data-qaid='settings_button_label'
          label={'Advanced'}
          style={{ color: !isBasic ? '#FFF' : '#051D33' }}
        />
      </Button>
    </Grid>
  );
};

export default PizzasModal;
